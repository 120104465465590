.menu-block {
  position: fixed;
  right: 5px;
  background: white;
  z-index: 900;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302),0 2px 6px 2px rgba(60,64,67,0.149);
  gap: 30px;
  padding: 25px;
  border-radius: 8px;
}

.menu-block button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}