.login {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: #666666;
}

.login .block-request {
  max-width: 400px;
  padding: 30px 0px;
}
.login h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  text-transform: uppercase;
}

.login .input {
  padding: 20px 0;
  width: 302px;
}

.login .form .fieldset {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 10px 0;
  box-sizing: border-box;
  width: 302px;
}

.login .form .fieldset .title {
  background-color: #eff0f1;
  line-height: 44px;
  padding-left: 25px;
  margin: 0;
  box-sizing:border-box;
  width:300px; 
  border:none;
  border-radius:3px 3px 0 0;
}

.login .form .fieldset .text {
  line-height: 44px;
  padding: 0 0 0 25px;
  margin: 0;
  font-size: 15px;
  box-sizing:border-box;
  width: 300px;
  border:none;
  border-radius:0 0 3px 3px;
}

.login .fieldset [placeholder]  {
  color:#999;
}

.login .form .block-submit {
  width:302px;
  box-sizing:border-box;
  padding:0;
  margin:0;
}

.login .form .extra-actions a {
  color: #666666;
}
.login .register, .login .forget {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #666666;
  cursor: pointer;
}
.login .register:hover,
.login .forget:hover {
  color: #333;
}

.login .form .submit {
  margin: 0;
  padding: 0 25px;
}

.login .request-submit {
  height: 45px;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 15px;
  background-color: #eff0f1;
  border: 1px solid #ccc;
  cursor: pointer;
  box-shadow: 0 1px #666;
  color: #666666;
}

.login .form .submit.red {
  color: red;
}

.login .comment {
  width: 300px;
  text-align: justify;
}

.login .wrapper .hidden {
  display: none;
}

.login .status {
  margin-top: 30px;
}
.login .extra-actions {
  margin-top: 30px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.login .error-message {
  margin-top: 30px;
  color: #BE1809;
}

.login .check-inbox {
  max-width: 400px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 15px;
}