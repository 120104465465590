.editable-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 2000;
  box-sizing: border-box;
}

.editable-block {
    margin: 48px auto;
    background-color: white;
    z-index: 2000;
    position: relative;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    min-width: 280px;
    overflow: scroll;
    height: 70%;
    box-sizing: border-box;
}

.editable-block.error {
  color: red;
  width: 200px;
}

.editable-block .textarea {
    width: 100%;
    border: medium none;
    resize: none;
    height: 40px;
    min-height: 40px;
    outline: none;
    font-weight: normal;
    font-size: 16px;
    box-sizing: border-box;
}
.editable-block .textarea-header {
  font-weight: bold;
}

.layout-1-column .editable-wrapper {
  top: 0;
}
.layout-1-column .editable-block {
  margin: 0 auto;
  border-radius: 0;
  height: 100%;
}