.note-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
}
.note-wrapper.active {
  background-color: #FFFBE9;
}
.note-wrapper.deleted {
  background-color: #FFDCE0;
}
.note-content {
  border: none;
  box-sizing: border-box;
  position: relative;
}
.note-item {
  border: none;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  overflow-y: hidden;
  word-wrap: break-word;
  white-space: pre-line !important;
}

.note-item.cropped {
  margin-bottom: 10px;
}

.note-item.cropped::after {
  content:"";
  position: absolute;
  bottom: -5px;
  left: 40%;
  height: 0px;
  width: 20%;
  border-bottom: 3px dotted gray;
}

.note-header {
  margin: 0 0 5px;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
}

.note-toolbar {
  height: 22px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.deleted .note-toolbar {
  flex-flow: row-reverse nowrap;
}
.editable-block .note-toolbar {
  margin-top: 0;
  margin-bottom: 15px;
}

.toolbar-item {
  display: none;
  height: 35px;
  width: 35px;
  margin: 0;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  cursor: auto;
}
.toolbar-item:hover {
  background-color: #F4EAD5;
}

.toolbar-icon {
  /* color: #007d7d;
  color: gray;
  color: white; */
  color: #007d7d;
  color: #2694ab;
  font-size: 18px !important;
  font-weight: normal !important;
}
.toolbar-icon.drag-icon {
  cursor: grabbing;
}
.toolbar-icon.delete-icon {
  color: coral;
  color: #ea7070;
  font-size: 20px !important;
}
.note-wrapper.active .toolbar-item,
.toolbar-item.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

