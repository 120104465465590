.toolbar {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  z-index: 900;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  box-sizing: border-box;
  /* border-bottom: 1px solid gray; */
  box-shadow: inset 0 -1px 0 0 #dadce0;
  padding: 5px 0;
}

.search-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px;
  padding: 0 10px;
  border-radius: 20px;
  /* background-color: #f1f3f4; */
  width: 50%;
}
.search-button {
  background: transparent;
  border: none;
  outline: none;
  line-height: 0;
}
.search-button.active {
  cursor: pointer;
}
.search-input {
  border: none;
  outline: none;
  /* background-color: #f1f3f4; */
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  font-size: 16px;
}

.user-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 48px;
  height: 48px;
  font-size: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: gray;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  margin: 0;
  box-sizing: border-box;
}
.avatar.online {
  background-color: #ee6c4d;
}
.avatar.extra.online {
  background-color: #4dcfee;
}
@media screen and (max-width: 470px) {
  /* .search-button {
    display: none;
  } */
  .search-wrapper {
    gap: 0;
  }
  .notes-icon-wrapper {
    margin: 0;
  }
}
