.overlay {
  position: absolute;
  top: 0px;
  height: 100%;
  min-height: 752px;
  left: 0px;
  right: 0px;
  background-color: rgba(210, 215, 211, 0.8);
  z-index: 1000;
}
.layout-1-column .overlay {
  background-color: white;
}